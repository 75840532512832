import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Card, Box, CardContent, CardMedia, Grid, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CartProvider, useCart } from 'react-use-cart';
import axios from 'axios';

const useStyles = makeStyles({
    cartRoot: {
        // margin: '10px'
        // display: 'block',
        // overflow: 'scroll',
        paddingBottom: '25px',
   },
   cartList: {
    display: 'flex',
   },
   removeButton: {
    textTransform: 'unset',
   },
   cartUpdateButton: {
    minWidth: 'unset',
    width: '30px',
    margin: '5px'
   },
   cartItemDetails: {
    // margin: '15px',
   },
   cartItemTitle: {
    margin: '10px',
    fontWeight: 'bold',
    fontSize: '1rem'
   },
   cartItemQty: {
    margin: '10px',
   },
   cartRemoveButton: {
    margin: '10px',
    // marginTop: '10%'
   },
   textBold: {
    fontWeight: 'bold',
   },
   checkoutButton: {
    // backgroundColor: 'rgb(97, 162, 41)',
    backgroundColor: 'rgb(118 191 55)',
   },
   cartActionButtonsRoot: {
    justifyContent: 'center',
    paddingBottom: '80px'
   },
   link: {
    textDecoration: 'none',
   },
   listRoot: {
    listStyle: 'none',
   }
});

export default function Cart() {
    const classes = useStyles();
    const {
        isEmpty,
        totalUniqueItems,
        items,
        updateItemQuantity,
        removeItem,
        cartTotal,
        emptyCart
      } = useCart();    

    function handleCheckout(items) {
        console.log("Handling checkout...");
        console.log(items);

        const headers = {
            "Content-Type": 'application/json'
        }

        // axios.post('http://localhost:4242/create-checkout-session', {
        // axios.post('https://mathioligita.com/create-checkout-session', {
        axios.post('https://mathioli-gita-h4y3m.ondigitalocean.app/create-checkout-session', {
            line_items: items,
        }, headers)
        .then(function (response) {
            console.log(response);
            
            window.location.href = response.data.url;

            // Reset cart to empty state
            emptyCart();
        })
        .catch(function (error) {
            console.log(error);
        });
    }
    
    if (isEmpty) {
        return (
            <div>
                <h1>Cart</h1>
                <p>Your cart is empty</p>
            </div>
        )
    } else {
        return (
            <div>
                <Grid container className={classes.cartRoot} >
                    <h1>Cart ({totalUniqueItems})</h1>
                    <ul className={classes.listRoot}>
                        {items.map((item) => (
                            <li key={item.id}>
                                <Grid container>  
                                    <Grid item>
                                        <img src={item.thumbnail} className={classes.bookImage}></img>
                                    </Grid>
                                    <Grid item className={classes.cartItemDetails}>
                                        <Grid item className={classes.cartItemTitle}>
                                            <span>
                                                {item.title}
                                            </span>
                                        </Grid>
                                        <Grid item className={classes.cartItemQty}>
                                            <span className={classes.textBold}>Qty: </span>
                                            <Button onClick={() => updateItemQuantity(item.id, item.quantity - 1)} variant='outlined' size='small' className={classes.cartUpdateButton}> - </Button>
                                            <span>
                                                {item.quantity}
                                            </span>
                                            <Button onClick={() => updateItemQuantity(item.id, item.quantity + 1)} variant='outlined' size='small' className={classes.cartUpdateButton}> + </Button>
                                        </Grid>                 
                                        <Grid item className={classes.cartItemQty}>
                                            <span className={classes.textBold}>Price: </span>
                                            <span>Rs. {item.price}</span>
                                        </Grid>                 
                                        <Grid item className={classes.cartRemoveButton}>
                                            <Button onClick={() => removeItem(item.id)} variant='outlined' size='small' color='secondary' className={classes.removeButton} >
                                                Remove Item
                                            </Button>
                                        </Grid>                   
                                    </Grid>
                                </Grid>                      
                            </li>
                        ))}
                    </ul>
                    <Grid item xs={12}>
                        <span style={{
                            fontSize: '1.25rem',
                        }}>
                            <strong>Total: </strong>Rs. {cartTotal}
                        </span>
                    </Grid>
                </Grid>
                <Grid container className={classes.cartActionButtonsRoot} >
                    <Grid item xs={6} >
                        <Link to="/books" className={classes.link}>
                            <Button variant='contained'>
                                Back
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item xs={6}>
                        <Button onClick={() => handleCheckout(items)} variant='outlined' className={classes.checkoutButton}>
                            Checkout
                        </Button>
                    </Grid>
                </Grid>
            </div>
        )
    }
    
}
