import { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    desktopMessageDisplay: {
        // textAlign: 'center',
        backgroundColor: 'khaki',
        height: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    textMessage: {
        // marginTop: '50px',
    }
});

const DeviceDetector = () => {
  const [deviceType, setDeviceType] = useState("");
  const classes = useStyles();

  useEffect(() => {
    let hasTouchScreen = false;
    if ("maxTouchPoints" in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
      hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
      const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
      if (mQ && mQ.media === "(pointer:coarse)") {
        hasTouchScreen = !!mQ.matches;
      } else if ("orientation" in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        var UA = navigator.userAgent;
        hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }
    if (hasTouchScreen) {
      setDeviceType("Mobile");
    } else {
      setDeviceType("Desktop");
    }
  }, []);

    if (deviceType == "Mobile") {
        // alert("on mobile");
        return (
            <></>
        )
    } else {
        // alert("on desktop");
        return (
            <div className={classes.desktopMessageDisplay}>
                <span className={classes.textMessage}>Please visit the site on a mobile device for the best experience.</span>
            </div>
        )    
  }

//   return <div>I am rendered on: {deviceType}</div>;
};

export default DeviceDetector;