import { AppBar, makeStyles, Toolbar, Typography } from '@material-ui/core';
import React from 'react'
import logo from '../../logo.svg';

import './titlebar.css';

const useStyles = makeStyles(theme => ({
    root: {
        paddingBottom: '56px',
    },
    title: {
        flexGrow: 1,
        fontFamily: 'Hind',
        textAlign: 'center',
        fontSize: '1.3rem',
    },
    responsive: {
        maxWidth: '100%',
        height: '3rem',
        display: 'none',
    },
    toolbarRoot: {
        margin: 'auto'
    }
}));

export default function TitleBar() {

    const classes = useStyles();

    return (
        <header className={classes.root}>
            {/* <img src={logo} alt='Logo'></img>
            <span>
                Mathioli Gita
            </span> */}
            <AppBar position="fixed">
                <Toolbar className={classes.toolbarRoot}>
                    <img src={logo} alt='Logo' className="logo-img"></img>
                    <Typography variant="h5" className={classes.title}>
                        Mathioli Gita
                    </Typography>
                </Toolbar>
            </AppBar>
        </header>
    )
}
