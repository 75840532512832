import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActionArea, CardMedia, CardContent, Typography, Paper, Icon } from '@material-ui/core';
import ReactPlayer from 'react-player/youtube';
import videos from '../../videos.json';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons';
import { ImBooks } from 'react-icons/im';
import { RiVideoFill } from 'react-icons/ri';
import { MdOutlineMail } from 'react-icons/md';
import { FiArrowRight } from 'react-icons/fi';

import './home.css';
import { findByLabelText } from '@testing-library/react';
import { Button } from '@mui/material';
// import AmmaLandingImage from '/images/amma.png';

const useStyles = makeStyles({
    root: {
        paddingBottom: 40,
        marginBottom: '20px',
        '& p': {
        },
    },
    cardRoot: {
        width: 'fit-content',
        margin: 'auto',
        // marginTop: 10
    },
    mainVideo: {
        maxWidth: '100vw',
        height: 'auto',
        margin: 'auto',
    },
    moreVideos: {
        fontWeight: 'bold',
        fontFamily: 'Hind',
        padding: 10,
    },
    subTitles: {
        fontFamily: 'Hind',
        padding: 10,
    },
    subRoot: {
        display: 'flex',
        marginBottom: '1.5rem',
    },
    quoteBlockContainer: {
        backgroundColor: 'antiquewhite',
    },
    quoteBlock: {
        padding: '10px',
        width: '80%',
        margin: 'auto',
    },
    logo: {
        fontSize: '2.5rem',
        // display: 'block',
        width: '100%',
    },
    callToActionSection: {
        // listStyle: 'none',
    },
    actionItem: {
        // marginTop: '8px',
        // marginBottom: '8px',
        margin: '10px auto 10px',
        border: '1px solid grey',
        borderRadius: '5px',
        width: '80%',
        '&:hover': {
            backgroundColor: 'lightgrey',
        },
        maxWidth: '700px',
    },
    actionLink: {
        textDecoration: 'none',
        color: 'inherit',        
    }
   
});

export default function Home() {

    const [currentVideo, setcurrentVideo] = useState(videos[0]);
    
    const classes = useStyles();

    return (
        <div className={classes.root}>  
            <div className="ammaLandingImageContainer">
                <img src="/images/hero-landscape.jpeg" />
            </div>
            <div style={{
                textAlign: 'center',
                marginTop: '10px'
            }}>
                <h3>Welcome To Mathioli Gita</h3>
                <p>Click on the boxes below to explore our site!</p>
                <div className={classes.callToActionSection}>
                    <div className={classes.actionItem} >                    
                        <a href='/books' className={classes.actionLink}>
                            <ImBooks className={classes.logo} />
                            Browse and purchase our collection of books
                        </a>
                    </div>                    
                    <div className={classes.actionItem} >
                        <a href='/videos' className={classes.actionLink}>
                            <RiVideoFill className={classes.logo} />
                            Watch our videos
                        </a>
                    </div>
                    <div className={classes.actionItem} >
                        <a href='/contact' className={classes.actionLink}>
                            <MdOutlineMail className={classes.logo} />
                            Reach out to us
                        </a>
                    </div>
                </div>
            </div>                   
            <div className={classes.quoteBlockContainer}>
                <p className={classes.quoteBlock}>
                    "ஏணியும், தோணியும் அந்தந்த இடத்திலேயே இருக்கும்.  பயன்படுத்தவோர் தேவைக்கேற்ப அவற்றை உபயோகிப்பார்" 
                    <br />- <strong>மதிஓளி</strong> 
                </p>
            </div>
        </div>
    )
}
