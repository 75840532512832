import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, Link, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import books from '../../books.json';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useHistory } from "react-router-dom";
import { CartProvider, useCart } from 'react-use-cart';

const useStyles = makeStyles({
   root: {
    //    display: 'flex',
    //    flexWrap: 'wrap',
    //    justifyContent: 'space-evenly',
       maxWidth: '100vw',
       marginBottom: '25px',
       fontFamily: 'Hind',
       paddingBottom: '60px'
   },
   bookImage: {
       width: '150px',
       maxWidth: '80vw',
        margin: '10px 0 10px 0',
        // display: 'flex',
   },
   bookTitle: {
       margin: 'auto',
    //    width: '56%'
   },
   bookGroup: {
       margin: '10px',
       width: '30vw'
   },
   pageHeading: {
       marginTop: '1.5rem',
       marginLeft: '15px',
       fontWeight : 'bold',
       fontSize: '1.5rem',
       width: '-webkit-fill-available',
    //    textAlign: 'center',
   },
   actionButtons: {
       display: 'flex',
       justifyContent: 'space-evenly',
       marginTop: '2.5rem'
   },
   backButton: {
       margin: '15px',
       textTransform: 'capitalize'
   },
   bookDetails: {
       margin: '15px'
   },
   pageSubheading: {
       color: 'grey',
       margin: '15px'
   },
    topBar: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'lightGrey',
        height: '3rem',
        borderBottom: 'solid 0.35px black',
    }
});

var bookObject;

function Book(props) {
    const classes = useStyles();
    const [ currentBook, setCurrentBook ] = React.useState();
    const history = useHistory();
    const {
        isEmpty,
        totalUniqueItems,
        items,
        updateItemQuantity,
        removeItem,
        cartTotal,
        addItem,
        inCart,
        getItem,
      } = useCart();   

    // Run this useEffect only once, at page load 
    useEffect(() => {

        if (currentBook) return;

        var bookTitle = props.match.params.title;
        console.log(bookTitle);

        // alert('In Book useEffect');

        // get book object
        for (var i=0; i<books.length; i++) {
            if (books[i].title == bookTitle) {
                // alert('FOUND BOOK...');
                setCurrentBook(books[i]);
                bookObject = books[i];
                console.log(bookObject);
                break;
            }
        }
    })

    function getItemQty(book) {

        var item = getItem(book.id);
        console.log(item);

        if (!item) return 0;
        
        console.log(item.quantity);

        return item.quantity;
    }
    
    function checkItemInCart(book) {
        var qty = getItemQty(book);
        console.log("Qty: " + qty);
        if (inCart(book.id) && qty >= 1) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <>
            {/* <Button variant="text" startIcon={<ArrowBackIcon />} onClick={() => window.location.href='/books'}> */}
            <div className={classes.topBar} >
                <Button className={classes.backButton} variant="text" startIcon={<ArrowBackIcon />} onClick={() => history.push("/books")}>
                    Back
                </Button>
                <Link href='/cart' >
                    <Button startIcon={<ShoppingCartIcon />} style={{
                        textAlign: 'right',
                        textTransform: 'capitalize',
                        marginRight: '0px',
                        marginTop: '7%',
                    }} >
                        Cart  
                    </Button>
                </Link>
            </div>
            <Typography className={classes.pageHeading} variant="h2">{props.match.params.title}</Typography>
            <Typography className={classes.pageSubheading} variant="subtitle">{bookObject && bookObject.category}</Typography>

            <div className={classes.root}>           
                {
                    bookObject != null ?
                        <div>                        
                            {/* <Typography className={classes.pageHeading} variant="h2">{props.match.params.title}</Typography> */}
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <img className={classes.bookImage} src={bookObject.thumbnail} />
                            </div>
                            <div className={classes.bookDetails}>
                                <Typography><strong>Price:</strong> Rs. {bookObject.price}</Typography>
                                <p>{bookObject.description}</p>
                            </div>

                            <div className={classes.actionButtons}>
                                {/* <Button href="/books" variant="contained" color="default">Back</Button> */}
                                {
                                    checkItemInCart(bookObject) ? 
                                        <div>
                                            <Button onClick={() => updateItemQuantity(bookObject.id, getItemQty(bookObject) - 1)} variant='contained' size='small' className={classes.cartUpdateButton}> - </Button>
                                            <span>
                                                {getItemQty(bookObject)}
                                            </span>
                                            <Button onClick={() => updateItemQuantity(bookObject.id, getItemQty(bookObject) + 1)} variant='contained' size='small' className={classes.cartUpdateButton}> + </Button>            
                                        </div>
                                    :
                                    <Button onClick={() => addItem(bookObject)} variant="contained" startIcon={<ShoppingCartIcon />} color="primary" style={{textTransform: 'unset'}}>Add to Cart</Button>
                                }
                            </div>

                        </div>
                        :
                        <CircularProgress style={{
                            marginTop: '2rem',
                        }} />
                }    
            </div>
        </>
    )
}

export default withRouter(Book);