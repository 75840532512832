import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const useStyles = makeStyles({
    button: {
        textTransform: 'unset',
        textDecoration: 'none',
    },
    buttonLink: {
        textDecoration: 'none',
    },
    purchaseDetails: {
        height: '3rem',
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'space-around',        
    }
})

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Checkout = () => {
    const classes = useStyles();
    const { search } = useLocation();
    let query = useQuery();

    useEffect(() => {
        // http://localhost:3000/checkout?canceled=true
        console.log(search);
        console.log(query);
        console.log(query.get("success"));
    })

  if (useQuery().get("success") == "true") {
      return (
          <section>
              <div className="product">       
                  <h2>Purchase Successful</h2>
                  <div className="description">
                      <p>Thank you for your purchase!</p>
                      <div className={classes.purchaseDetails}>
    
                      </div>
                  </div>
                  <div className={classes.buttonRoot} >
                      <Link to='/' className={classes.buttonLink}>
                          <Button variant='outlined' className={classes.button} >Back to Home</Button>
                      </Link>
                      <Link to='/books' className={classes.buttonLink}>
                          <Button variant='outlined' color='primary' className={classes.button} >Browse more books</Button>
                      </Link>
                  </div>
    
              </div>
          </section>
      )
    } else {
      return (
          <section>
              <div className="product">       
                  <h2>Purchase Failed</h2>
                  <div className="description">
                      <p>The purchase did not go through!</p>
                      <div className={classes.purchaseDetails}>
    
                      </div>
                  </div>
                  <div className={classes.buttonRoot} >
                      <Link to='/' className={classes.buttonLink}>
                          <Button variant='outlined' className={classes.button} >Back to Home</Button>
                      </Link>
                      <Link to='/books' className={classes.buttonLink}>
                          <Button variant='outlined' color='primary' className={classes.button} >View books</Button>
                      </Link>
                  </div>
    
              </div>
          </section>
      )
  }
  
}

export default Checkout