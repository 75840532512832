import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActionArea, CardMedia, CardContent, Typography } from '@material-ui/core';
import ReactPlayer from 'react-player/youtube';
import videos from '../../videos.json';

import './videos.css';
import { findByLabelText } from '@testing-library/react';
import { Button } from '@mui/material';

const useStyles = makeStyles({
    root: {
        paddingBottom: 40,
    },
    cardRoot: {
        width: 'fit-content',
        margin: 'auto',
        // marginTop: 10
    },
    mainVideo: {
        maxWidth: '100vw',
        height: 'auto',
        margin: 'auto',
    },
    moreVideos: {
        fontWeight: 'bold',
        fontFamily: 'Hind',
        padding: 10,
    },
    subTitles: {
        fontFamily: 'Hind',
        padding: 10,
    },
    subRoot: {
        display: 'flex',
        marginBottom: '1.5rem',
    }
   
});


export default function Home() {

    const [currentVideo, setcurrentVideo] = useState(videos[0]);
    
    const classes = useStyles();

    function handlePlayVideo(video) {
        setcurrentVideo(video);
        window.scrollTo(0, 0);
    }
    
    function mainVideo()  {
        return(
        <div>
            <div className="mainVideoRoot">
                {/* <img className={classes.mainVideo} src={video.thumbnail} alt={video.thumbnail} /> */}
                {/* <video controls className={classes.mainVideo} src={video.video+"?origin=https://mathioligita.com"} poster={video.thumbnail}></video> */}
                {/* <video controls className={classes.mainVideo} src={video.video} poster={video.thumbnail}></video> */}
                {/* <iframe id="player" type="text/html" width="640" height="390"
                    src={video.video}
                    src={video.video+"?origin=http://localhost:3000"}
                    frameborder="0">                                
                </iframe> */}

                <iframe 
                    width="853" 
                    height="480" 
                    className={classes.mainVideo}
                    // src="https://www.youtube.com/embed/fhuKZK6JOHE" 
                    src={currentVideo.video + "?modestbranding=1?origin=https://mathioligita.com"} 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen
                >                               
                </iframe>
            </div>
            <Typography variant="h5" className={classes.moreVideos}>{currentVideo.title}</Typography>
            <Typography variant="h6" className={classes.moreVideos}>
                More Videos
            </Typography>
        </div>
        )
    }

    const listVideos = videos.map((video, index) =>
        <div>
            {
                video != currentVideo ?                                 
                <button onClick={() => handlePlayVideo(video)} className="videoButton">
                    <div className={classes.subRoot}>
                        <div className="subVideo">
                            {/* <img src={video.thumbnail} alt="Video Thumbnail" width="160px" /> */}
                            {/* <video controls src={video.video} poster={video.thumbnail}></video> */}
                            <img src={"https://img.youtube.com/vi/" + video.video.split("/")[4] + "/0.jpg"} alt="Video Thumbnail" width="160px" />
                            {/* <iframe 
                                width="160" height="120" 
                                src={video.video + "?origin=https://mathioligita.com"} 
                                    title="YouTube video player" 
                                    frameborder="0" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                    allowfullscreen
                            >                                  
                            </iframe> */}
                        </div>
                        <Typography className={classes.subTitles}>{video.title}</Typography>                    
                    </div>
                    {/* <Button
                        onClick={() => handlePlayVideo(video)}
                    >
                        Play Video
                    </Button> */}
                </button>
                :
                null
            }
        </div>    
    );

    return (
        <div className={classes.root}>
            {mainVideo()}
            {listVideos}        
        </div>
    )
}
