import { Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import img from '../../about.jpg';

const useStyles = makeStyles({
    pageTitle: {
        marginLeft: 10,
        marginTop: 10,
        fontWeight : 'bold',
        fontSize: '1.5rem'
    },
    banner: {
        width: '90vw',
        margin: '1vh 5vw 1vh 5vw',
    },
    aboutText: {
        margin: '8px 20px 40px 20px',
        // maxWidth: '800px'
    },
    aboutBody: {
        marginBottom: "70px",
    }
});

const About = () => {

    const classes = useStyles();

    return (
        <div className={classes.aboutBody}>
            <Typography className={classes.pageTitle}>About Us</Typography>
            <img className={classes.banner} src={img} />
            <Typography className={classes.aboutText}>
                Mathioli Gita is an attempt to reach the words of Guru Pujyashri Mathioli Saraswathy to the reading public. 
                The books written by Her provide people with a guide to improve their lives and make it more meaningful.<br /><br /> 
                Her thoughts are interwoven with stories in each chapter to make the reading simple and easy to understand. 
                Reflecting or contemplating on the stories and underlying principles, will transform a person from within and 
                make it easy for us to understand life and its vicissitidues.
            </Typography>
            <Divider />
            <Typography className={classes.pageTitle} variant="h6">About the Speaker</Typography>
            <Typography className={classes.aboutText}>
                Prof. Dr. Parveen Sultana, M.A, B.Ed, PhD<br /><br />       

                Prof Parveen Sultana is a doctor of philosophy and a speaker of repute.
                She is a literary public speaker and travels around the world on speaking engagements. She has written 7 research books 
                and is currently professor in the Tamil department of  Justice Basheer Ahamed womens college.
            </Typography>
        </div>
    )
}

export default About;
