import { Button, CardContent, Grid, TextField, Typography, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles({
    pageTitle: {
        marginLeft: 10,
        marginTop: 10,
        fontWeight : 'bold',
        fontSize: '1.5rem'
    },
    banner: {
        width: '90vw',
        margin: '1vh 5vw 1vh 5vw',
    },
    formRoot: {
        margin: 10
    },
    TextField: {
        marginBottom: 8
    },
    social: {
        color: "black",
        display: "flex",
        justifyContent: 'center',
        margin: '15px',
    }
});

const ContactUs = () => {
  
    const form = useRef();
    const classes = useStyles();


    const toastifySuccess = () => {
       toast.success(
           'Form Sent!', {
               position: 'top-center',
               autoClose: 5000,
            //    hideProgressBar: true,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: false,
               className: 'submit-feedback success',
               toastId: 'notifyToast'
           }
       )
    };
    
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, 
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID, 
            form.current, 
            process.env.REACT_APP_EMAILJS_USER_ID)
            .then((result) => {
                console.log(result.text);
                
                toastifySuccess();
            }, (error) => {
                console.log(error.text);
            });
    };

  return (
    // <form ref={form} onSubmit={sendEmail}>
    //   <label>Name</label>
    //   <input type="text" name="from_name" />
    //   <label>Email</label>
    //   <input type="email" name="reply_to" />
    //   <label>Message</label>
    //   <textarea name="message" />
    //   <input type="submit" value="Send" />
    // </form>
    <>
        <Typography className={classes.pageTitle} >Contact Us</Typography>
            <Card>
                <CardContent>
                    <form ref={form} onSubmit={sendEmail}>
                        <Grid container spacing={1}>
                            <Grid xs={12} sm={6} item>
                                <TextField name="from_name" type="text" label="Name" placeholder="Enter your name" variant="outlined" fullWidth required/>
                            </Grid>
                            <Grid xs={12} sm={6} item>
                                <TextField name="reply_to" type="email" label="Email" placeholder="Enter your email" variant="outlined" fullWidth required/>
                            </Grid>
                            <Grid xs={12} item>
                                <TextField name="message" type="text" label="Message" placeholder="Enter your message" variant="outlined" fullWidth multiline rows={4} required/>
                            </Grid>
                            <Grid xs={12} item>
                                <Button type="submit" value="Send" variant="contained" color="primary" fullWidth>Submit</Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
                <ToastContainer />
            </Card>
            <div className={classes.social}>
                {/* <EmailIcon color="primary" />&nbsp;&nbsp; */}
                <FontAwesomeIcon icon={faEnvelope} />&nbsp;&nbsp;
                    <a href="mailto:uthiradambooks@gmail.com">
                        <Typography>uthiradambooks@gmail.com</Typography>
                    </a>
            </div>
            <div className={classes.social}>
                
                <FontAwesomeIcon icon={faPhoneAlt} />
                {/* <LocalPhoneIcon color="primary" /> */}
                &nbsp;&nbsp;<Typography>+91 9884054030</Typography>
            </div>
    </>
  );
};

export default ContactUs;