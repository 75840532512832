import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import books from '../../books.json';
import { Typography, Card, Box, CardContent, CardMedia, Grid, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useHistory } from "react-router-dom";
import { CartProvider, useCart } from 'react-use-cart';

const useStyles = makeStyles({
   root: {
    //    display: 'flex',
    //    maxWidth: '90vw',
    //    flexWrap: 'wrap',
    //    justifyContent: 'flex-start',
    //    marginBottom: '5rem',
    //    marginLeft: '15vw',
    //    fontFamily: 'Hind',
       marginBottom: '4rem',
    //    'div:nth-child(n)': {
    //         backgroundColor: 'blue',
    //     }
   },
   bookRoot: {
     margin: 'auto',
   },
   bookImage: {
    //    margin: 'auto',
    //    width: '100%',
    // maxWidth: '100px',
       width: '100px',
   },
   bookTitle: {
        color: 'black',
        // marginTop: '0px',
        marginLeft: '15px',
        height: 'fit-content'
    },
    bookPrice: {
        fontSize: '15px',
        color: 'black',
        marginLeft: '15px',    
   },
   bookGroup: {
    //    margin: '10px',
    paddingTop: '10px',
    paddingBottom: '10px',
    //    width: '30vw'
       width: '100%',
    //    display: 'flex',
   },
   pageHeading: {
       marginLeft: 10,
    //    marginTop: 10,
       padding: 10,
       fontWeight : 'bold',
       fontSize: '1.5rem'
   },
   filterRoot: {
       display: 'flex',
       flexWrap: 'wrap',
       padding: '0 15px 15px 15px'
   },
   chipItem: {
       marginTop: '5px',
       display: 'none',
   },
   bookCard: {
    //    maxWidth: '85%',
       margin: '5px auto',
       borderBottom: '1px solid black',
    //    backgroundColor: 'blue'
   },
   cardContent: {
       width: '60%',
       marginLeft: 'auto',
   },
//    greyBackground: {
//        backgroundColor: 'lightGrey',
//     },
    bookButton: {
        marginLeft: '15px',        
    },
    topBar: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'lightGrey',
        height: '3rem',
        borderBottom: 'solid 0.35px black',
    },
    cartUpdateButton: {
        minWidth: 'unset',
        width: '30px',
        margin: '5px'
    },
});

export default function Books() {
    const classes = useStyles();
    const [currentCategory, setcurrentCategory] = useState('General');
    const history = useHistory();
    const {
        isEmpty,
        totalUniqueItems,
        items,
        updateItemQuantity,
        removeItem,
        cartTotal,
        addItem,
        inCart,
        getItem,
      } = useCart();   

    const ItemQuantityUpdateComponent = ({item}) => {
        return (
            <div>
                <Button onClick={() => updateItemQuantity(item.id, item.quantity - 1)} variant='contained' size='small' className={classes.cartUpdateButton}> - </Button>
                <span>
                    {item.quantity}
                </span>
                <Button onClick={() => updateItemQuantity(item.id, item.quantity + 1)} variant='contained' size='small' className={classes.cartUpdateButton}> + </Button>            
            </div>
        )
    }

    function getItemQty(book) {

        var item = getItem(book.id);
        console.log(item);

        if (!item) return 0;
        
        console.log(item.quantity);

        return item.quantity;
    }

    function checkItemInCart(book) {
        var qty = getItemQty(book);
        console.log("Qty: " + qty);
        if (inCart(book.id) && qty >= 1) {
            return true;
        } else {
            return false;
        }
    }
    
    function ListBooks() {
        return (
            books.map((book, index) =>
                <div key={index}>
                {
                book.category == currentCategory &&
                    <div className={classes.bookCard}>
                            <div style={{
                                display: 'flex',                            
                            }}>
                                <img src={book.thumbnail} className={classes.bookImage}></img>
                                <Grid container className={classes.bookGroup}>
                                    <Grid item xs={12}>
                                        <p className={classes.bookTitle}>{book.title}</p>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <span className={classes.bookPrice}>
                                            <strong>Price: </strong>
                                            Rs. {book.price}
                                        </span>
                                    </Grid>
                                    <Grid item className={classes.bookButton}>
                                        <Link to={"/book/"+book.title} style={{textDecoration: 'none' }} >
                                            <Button variant='outlined' size='small' style={{textTransform: 'unset'}} >View Book</Button>
                                        </Link>
                                    </Grid>
                                    <Grid item className={classes.bookButton}>
                                        {
                                            checkItemInCart(book) ? 
                                                <div>
                                                    <Button onClick={() => updateItemQuantity(book.id, getItemQty(book) - 1)} variant='contained' size='small' className={classes.cartUpdateButton}> - </Button>
                                                    <span>
                                                        {getItemQty(book)}
                                                    </span>
                                                    <Button onClick={() => updateItemQuantity(book.id, getItemQty(book) + 1)} variant='contained' size='small' className={classes.cartUpdateButton}> + </Button>            
                                                </div>
                                            :
                                                <Button onClick={() => addItem(book)} variant='outlined' size='small' style={{textTransform: 'unset'}}>Add to cart</Button>
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                    </div>               
                }
                </div>
            )
        );
    }
    
    function handleClick(e) {
        if (e.target.outerHTML.includes("General")) {
            setcurrentCategory("General");
        } else if (e.target.outerHTML.includes("Children")) {
            setcurrentCategory("Children");
        } else if (e.target.outerHTML.includes("Biography")) {
            setcurrentCategory("Biography");
        } else if (e.target.outerHTML.includes("Poetry")) {
            setcurrentCategory("Poetry");
        } else if (e.target.outerHTML.includes("Philosophy")) {
            setcurrentCategory("Philosophy");
        }        
    }

    return (
        <>
            <div className={classes.topBar} >
                {/* <Button className={classes.backButton} variant="text" startIcon={<ArrowBackIcon />} onClick={() => history.push("/books")}>
                    Back
                </Button> */}
                <Typography className={classes.pageHeading} variant="h2">Uthiradam Books</Typography>                
                <a href='/cart' >
                    <Button startIcon={<ShoppingCartIcon />} style={{
                        textAlign: 'right',
                        textTransform: 'capitalize',
                        marginRight: '0px',
                        marginTop: '7%',
                    }} >
                        Cart  
                    </Button>
                </a>
            </div>
            {/* <Typography className={classes.pageHeading} variant="h2">Uthiradam Books</Typography> */}
            <Stack direction="row"  className={classes.filterRoot}>
                {/* <Chip label="General" variant={currentCategory == "General" ? "filled" : "outlined"} onClick={handleClick} />
                <Chip label="Children" variant={currentCategory == "Children" ? "filled" : "outlined"} onClick={handleClick} />
                <Chip label="Biography" variant={currentCategory == "Biography" ? "filled" : "outlined"} onClick={handleClick} />
                <Chip label="Poetry" variant={currentCategory == "Poetry" ? "filled" : "outlined"} onClick={handleClick} />
                <Chip className={classes.chipItem} label="Philosophy" variant={currentCategory == "Philosophy" ? "filled" : "outlined"} onClick={handleClick} /> */}
                <Chip style={{
                    marginTop: '10px',
                    marginRight: '8px'
                }}
                label="General" variant={currentCategory == "General" ? "filled" : "outlined"} onClick={handleClick} />
                <Chip style={{
                    marginTop: '10px',
                    marginRight: '8px'
                }}
                label="Children" variant={currentCategory == "Children" ? "filled" : "outlined"} onClick={handleClick} />
                <Chip style={{
                    marginTop: '10px',
                    marginRight: '8px'
                }}
                label="Biography" variant={currentCategory == "Biography" ? "filled" : "outlined"} onClick={handleClick} />
                <Chip style={{
                    marginTop: '10px',
                    marginRight: '8px'
                }}
                label="Poetry" variant={currentCategory == "Poetry" ? "filled" : "outlined"} onClick={handleClick} />
                <Chip style={{
                    marginTop: '10px',
                    marginRight: '8px'
                }}
                label="Philosophy" variant={currentCategory == "Philosophy" ? "filled" : "outlined"} onClick={handleClick} />
            </Stack>
            {/* <div className={classes.root}> */}
            <div className={classes.root}>
                <ListBooks />
            </div>
        </>
    )
}
