import React from 'react';
import { Card, Typography, CardActionArea, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        maxWidth: '70vw',
        backgroundColor: 'blue',
    },
    errorCard: {
        justifyContent: 'center',
    }
});

const DeviceErrorPage = () => {

    const classes = useStyles();

    return (
        <div classsName={classes.root}>
            <Card>
                <CardActionArea  className={classes.errorCard}>
                    {/* <CardMedia
                    component="img"
                    height="140"
                    image="/static/images/cards/contemplative-reptile.jpg"
                    alt="green iguana"
                    /> */}
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Oh no!
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            This website is not yet compatable on Desktop. Please use a mobile device and try again!
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </div>
    )
}

export default DeviceErrorPage
