import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import InfoIcon from '@material-ui/icons/Info';
import EmailIcon from '@material-ui/icons/Email';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles({
    root: {
        width: '100vw',
        position: 'fixed',
        bottom: 0,
        borderTop: '1px solid',
        marginTop: '500px',
        zIndex: '2',
        // display: 'none'
    },
    MuiBottomNavigationActionRoot: {
        minWidth: "unset",
        maxWidth: "unset",
    }
});

export default function Navbar () {
    const classes = useStyles();
    const [value, setValue] = React.useState();
    let history = useHistory();
    const location = useLocation();

    useEffect(() => {
        console.log(location.pathname);
        if (location.pathname == '/') setValue(0);
        if (location.pathname == '/books' || location.pathname.includes('/book')) setValue(1);
        if (location.pathname == '/videos') setValue(2);
        if (location.pathname == '/about') setValue(3);
        if (location.pathname == '/contact') setValue(4);
    })

    return (
        <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
                if(newValue == 0) {
                    history.push('/');
                }
                else if(newValue == 1) {
                    history.push('/books');
                }
                else if(newValue == 2) {
                    history.push('/videos');
                }
                else if(newValue == 3) {
                    history.push('/about');
                }
                else if(newValue == 4) {
                    history.push('/contact');
                }
            }}
            showLabels
            className={classes.root}
        >
            <BottomNavigationAction label="Home" icon={<HomeIcon />} className={classes.MuiBottomNavigationActionRoot} />
            <BottomNavigationAction label="Books" icon={<ImportContactsIcon />} className={classes.MuiBottomNavigationActionRoot} />
            <BottomNavigationAction label="Videos" icon={<OndemandVideoIcon />} className={classes.MuiBottomNavigationActionRoot} />
            <BottomNavigationAction label="About" icon={<InfoIcon />} className={classes.MuiBottomNavigationActionRoot} />
            <BottomNavigationAction label="Contact" icon={<EmailIcon />} className={classes.MuiBottomNavigationActionRoot} />
        </BottomNavigation>
    )
}
