import React, { useEffect, useState } from 'react';
import './App.css';
import { Navbar, TitleBar, Home, Books, Book, About, Contact, DeviceErrorPage, Videos, Cart, DeviceDetector } from './components';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import PrivacyPolicy from './components/Policy/PrivacyPolicy';
import TermsOfService from './components/Policy/TermsOfService';
import Returns from './components/Policy/Returns';
import Checkout from './components/Cart/Checkout';

function App() {

  useEffect(() => {
    let details = navigator.userAgent;
    let regexp = /android|iphone|kindle|ipad/i;
    let isMobileDevice = regexp.test(details);

    // alert(details);

    // if (deviceType == "Mobile") {
      // alert("You are using a Mobile Device");
      // sessionStorage.setItem('deviceType', 'Mobile')
    // } else {
      // alert("You are using Desktop, " + deviceType);
      // sessionStorage.setItem('deviceType', 'Desktop')
    // }
  })
  
  return (
    <Router>

    <>
      <TitleBar />
      <Navbar />
      <DeviceDetector />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/books">
          <Books />
        </Route>
        <Route exact path="/videos">
          <Videos />
        </Route>
        <Route path="/book/:title">
          <Book />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/contact">
          <Contact />
        </Route>
        <Route exact path="/cart">
          <Cart />
        </Route>
        <Route exact path="/checkout">
          <Checkout />
        </Route>
        <Route exact path="/privacy">
          <PrivacyPolicy />
        </Route>
        <Route exact path="/privacy">
          <PrivacyPolicy />
        </Route>
        <Route exact path="/terms-of-service">
          <TermsOfService />
        </Route>
        <Route exact path="/returns">
          <Returns />
        </Route>
      </Switch>
    </>

    </Router>
  );
}

export default App;
